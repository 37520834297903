module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/png/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"547b5e2be1fb73196185fe3b1fad3f23"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","pt"],"defaultLanguage":"en","redirect":true,"siteUrl":"https://crocheniacs.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"backend":{"loadPath":"/locales/{{lng}}/{{ns}}.json"}},"pages":[{"matchPath":"/:lang?/pattern/:uid","getLanguageFromPath":true}],"exclude":["/sitemap-index.xml","/sitemap-*.xml","/robots.txt"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
